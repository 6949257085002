module.exports = {
  i18n: {
    defaultLocale: 'cs',
    locales: ['de', 'cs', 'en'],
  },
  // this will download the translations from locize directly, in client (browser) and server (node.js)
  // DO NOT USE THIS if having a serverless environment => this will generate too much download requests
  //   => https://github.com/locize/i18next-locize-backend#important-advice-for-serverless-environments---aws-lambda-google-cloud-functions-azure-functions-etc
  backend: {
    projectId: '731ee0b0-f581-4554-8c6f-ce49fa7b98dd',
    apiKey: 'c94a40a6-86f1-4f19-af51-1a49e94b9919',
    referenceLng: 'cs',
    private: false,
    allowedAddOrUpdateHosts: ['localhost', 'www.ubytovaniukasny.cz'],
    addPath: 'https://api.locize.app/missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
    reloadInterval: 60000000,
  },
  use: [require('i18next-locize-backend/cjs')],
  ns: ['common'], // the namespaces nees to be listed here, to make sure they got preloaded
  serializeConfig: false, // because of the custom use i18next plugin
  saveMissing: true,
  debug: false,
};

// for a serverless environment bundle the translations first. See downloadLocales script in package.json
// and configre this file like this:
// module.exports = {
//   i18n: {
//     defaultLocale: 'en',
//     locales: ['en', 'de'],
//   }
// }
