import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import nextI18NextConfig from '../next-i18next.config.js';

import dayjs from 'dayjs';
import qs from 'qs';
import axios from 'axios';
import { ViewportProvider } from 'use-viewport';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.tz.setDefault('Europe/Prague');

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'flag-icons/css/flag-icons.min.css';

import { addLocale } from 'primereact/api';
import { appWithTranslation } from 'next-i18next';

addLocale('cs', {
  firstDayOfWeek: 1,
  dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  dayNamesMin: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
  monthNames: [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec',
  ],
  monthNamesShort: ['led', 'ún', 'bře', 'dub', 'kvě', 'čer', 'čvn', 'srp', 'zář', 'říj', 'lis', 'pro'],
  today: 'dnes',
  clear: 'Smazat',
});

addLocale('de', {
  startsWith: 'startet mit',
  contains: 'enhält',
  notContains: 'enthält nicht',
  endsWith: 'endet mit',
  equals: 'gleich',
  notEquals: 'nicht gleich',
  noFilter: 'kein Filter',
  lt: 'weniger als',
  lte: 'weniger oder gleich wie',
  gt: 'größer als',
  gte: 'größer oder gleich wie',
  dateIs: 'Datum ist',
  dateIsNot: 'Datum ist nicht',
  dateBefore: 'Datum ist vor',
  dateAfter: 'Datum ist nach',
  custom: 'Benutzerdefiniert',
  apply: 'Übernehmen',
  matchAll: 'Passt auf alle',
  matchAny: 'Passt auf einige',
  addRule: 'Regel hinzufügen',
  removeRule: 'Regel entfernen',
  accept: 'Ja',
  reject: 'Nein',
  choose: 'Auswählen',
  upload: 'Upload',
  cancel: 'Abbruch',
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez'],
  today: 'Heute',
  clear: 'Löschen',
  weekHeader: 'Wo',
  firstDayOfWeek: 1,
  dateFormat: 'dd.mm.yy',
  weak: 'Schwach',
  medium: 'Mittel',
  strong: 'Stark',
  passwordPrompt: 'Passwort eingeben',
  emptyFilterMessage: 'Keine Einträge gefunden',
  emptyMessage: 'Keine Einträge gefunden',
  aria: {
    trueLabel: 'Richtig',
    falseLabel: 'Falsch',
    nullLabel: 'Nicht selektiert',
    pageLabel: 'Seite',
    firstPageLabel: 'Erste Seite',
    lastPageLabel: 'Letzte Seite',
    nextPageLabel: 'Nächste Seite',
    previousPageLabel: 'Vorherige Seite',
  },
});
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false }); // param=value1&param=value2
};

const MyApp = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  return <ViewportProvider>{router.isReady && <Component {...pageProps} />}</ViewportProvider>;
};

export default appWithTranslation(MyApp, nextI18NextConfig);
